import Link from 'next/link';
import React from 'react';

import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';
import { WagtailStreamfieldImage } from '@/types';
import Image from 'next/image';

type Props = {
  text: string;
  image: WagtailStreamfieldImage;
  button_page: { title: string; full_url: string } | null;
  button_url: string | null;
  button_text: string;
};

function CTA(props: Props) {
  const {
    button_page: buttonPage,
    button_url: buttonUrl,
    button_text: buttonText,
    image,
    text,
  } = props;

  return (
    <div className='streamfield cta'>
      <div className='cta__image'>
        <Image
          src={addBaseUrl(image.file.src)}
          width={image.file.width}
          height={image.file.height}
          layout='responsive'
          alt={image.file.alt}
          sizes='(min-width: 65em) 50vw, 100vw'
        />
      </div>
      <RawHtml html={text} className='cta__text flow' />
      {buttonPage ? (
        <Link href={removeBaseUrl(buttonPage.full_url)} passHref>
          <a className='cta__link  button button--primary'>{buttonText}</a>
        </Link>
      ) : null}
      {buttonUrl ? (
        <Link href={buttonUrl} passHref>
          <a className='cta__link button button--primary'>{buttonText}</a>
        </Link>
      ) : null}
    </div>
  );
}

export default CTA;
