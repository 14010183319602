/* eslint-disable jsx-a11y/no-redundant-roles */
import { WagtailStreamfieldImage } from '@/types';
import RawHtml from '@components/RawHtml';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';

type Course = {
  title: string;
  full_url: string;
};

type Props = {
  title: string;
  image?: WagtailStreamfieldImage;
  description: string;
  courses: Course[];
};

function TargetGroup(props: Props) {
  const { title, image, description, courses } = props;

  return (
    <div className='streamfield target-group'>
      <div className='target-group__header'>
        {image ? (
          <div className='target-group__image'>
            <Image src={addBaseUrl(image.file.src)} alt='' layout='fill' objectFit='cover' />
          </div>
        ) : null}
        <h2 className='target-group__title'>{title}</h2>
        <RawHtml className='target-group__title-text' html={description} />
      </div>
      <ul role='list' className='target-group__list'>
        {courses.filter(Boolean).map(course => (
          <li key={course.title} role='listitem'>
            <Link href={removeBaseUrl(course.full_url)} passHref>
              <a className='target-group__link'>
                {course.title}
                <HiOutlineArrowRight size={28} color='var(--clr-primary)' />
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TargetGroup;
