import { ParsedUrlQuery } from 'querystring';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

function removeBaseUrl(url: string) {
  if (url === '') return '/';

  return url.replace(baseUrl!, '');
}

function addBaseUrl(url: string) {
  return `${baseUrl!}${url}`;
}

function toQuerystring(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(Boolean)
    .map(entry => entry.join('='))
    .join('&');
}

export { addBaseUrl, removeBaseUrl, toQuerystring };
