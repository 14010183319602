import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import RawHtml from '@components/RawHtml';

type Props = {
  title: string;
  text: string;
};

function Accordion(props: Props) {
  const { title, text } = props;

  return (
    <AccordionPrimitive.Item className='accordion' value={title}>
      <AccordionPrimitive.Header className='accordion__header'>
        <AccordionPrimitive.Trigger className='accordion__trigger'>
          <div className='accordion__icon' aria-hidden='true'>
            <span />
            <span />
          </div>
          {title}
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className='accordion__content' forceMount>
        <RawHtml html={text} />
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
}

export default Accordion;
