import Rating from '@streamfields/Rating';
import Accordions from '@streamfields/Accordions';
import Buttons from '@streamfields/Buttons';
import CourseInformation from '@streamfields/CourseInformation';
import CTA from '@streamfields/CTA';
import Image from '@streamfields/Image';
import KursTypField from '@streamfields/KursTypField';
import Links from '@streamfields/Links';
import Message from '@streamfields/Message';
import Richtext from '@streamfields/Richtext';
import Table from '@streamfields/Table';
import TextBlockSnippet from '@streamfields/TextBlockSnippet';
import EyeCatcher from '@streamfields/EyeCatcher';
import Reservation from '@streamfields/Reservation';
import TargetGroup from '@streamfields/TargetGroup';
import Video from './Video';
import Media from './Media';

const streamfields = {
  accordion: Accordions,
  buttons: Buttons,
  code: Richtext,
  courseinformation: CourseInformation,
  cta: CTA,
  eyecatcher: EyeCatcher,
  image: Image,
  kurstypfield: KursTypField,
  links: Links,
  media: Media,
  message: Message,
  full_richtext: Richtext,
  rating: Rating,
  reservation: Reservation,
  simple_richtext: Richtext,
  table: Table,
  targetgroup: TargetGroup,
  textblocksnippet: TextBlockSnippet,
  video: Video,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
