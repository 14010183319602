import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage, WagtailSvg } from '@/types';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailStreamfieldImage | null;
  svg: WagtailSvg | null;
};

type Props = {
  link_kacheln: LinkTile[];
};

function Links(props: Props) {
  const { link_kacheln: linkKacheln } = props;

  return (
    <div className='streamfield links'>
      {linkKacheln.map(tile => (
        <Link
          key={tile.title}
          // href={tile.button_page ? removeBaseUrl(tile.button_page.full_url) : tile.button_url ?? ''}
          href='/'
          passHref
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className='links__tile'>
            <div className='title'>{tile.title}</div>
            {tile.image ? (
              <Image
                src={addBaseUrl(tile.image.file.src)}
                alt={tile.image.file.alt}
                layout='intrinsic'
                objectFit='contain'
                className='icon'
                width={78}
                height={78}
              />
            ) : null}
            {tile.svg ? (
              <Image
                src={tile.svg.file}
                alt={tile.svg.title}
                width={78}
                height={78}
                layout='fixed'
              />
            ) : null}
          </a>
        </Link>
      ))}
    </div>
  );
}

export default Links;
