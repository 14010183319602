import React from 'react';

type Field = {
  key: string;
  value: string;
};

type Props = {
  field: Field;
};

function KursTypField(props: Props) {
  const { field } = props;

  return (
    <div className='streamfield kurstypfield'>
      <p>{field.value}</p>
    </div>
  );
}

export default KursTypField;
