import { WagtailPage } from '@/types';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

type Button = {
  primary: boolean;
  button_text: string;
  trennlinie: null;
  hinweis: null;
  button_page: WagtailPage | null;
  button_url: string | null;
};

type Props = {
  buttons: Button[];
};

function Buttons(props: Props) {
  const { buttons } = props;

  return (
    <div className='streamfield buttons'>
      {buttons.map(button => (
        <Link
          href={button.button_page?.full_url ?? button.button_url ?? '/'}
          passHref
          key={button.button_text}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={clsx('buttons__link', 'button', { 'button--primary': button.primary })}>
            {button.button_text}
          </a>
        </Link>
      ))}
    </div>
  );
}

export default Buttons;
