import React from 'react';
import { TiStar, TiStarHalf } from 'react-icons/ti';

type StarProps = {
  rating: number;
  count: number;
};

function Star(props: StarProps) {
  const { rating, count } = props;

  if (rating >= count) {
    return (
      <span className='rating__star'>
        <TiStar size={40} fill='var(--clr-primary)' aria-hidden />
      </span>
    );
  }

  if (rating >= count - 1 && rating < count) {
    return (
      <span className='rating__star'>
        <TiStar size={40} fill='var(--clr-light-grey)' aria-hidden />
        <TiStarHalf size={40} fill='var(--clr-primary)' aria-hidden />
      </span>
    );
  }

  return (
    <span className='rating__star'>
      <TiStar size={40} fill='var(--clr-light-grey)' aria-hidden />
    </span>
  );
}

type Props = {
  value: string;
};

function Rating(props: Props) {
  const { value } = props;
  const rating = parseInt(value, 10);

  if (Number.isNaN(rating)) return null;

  return (
    <div className='streamfield rating'>
      <div className='rating__stars' aria-hidden>
        <Star rating={rating} count={1} />
        <Star rating={rating} count={2} />
        <Star rating={rating} count={3} />
        <Star rating={rating} count={4} />
        <Star rating={rating} count={5} />
      </div>
      <p>
        Kursbewertung <strong>{value}</strong> von <strong>5</strong>
      </p>
    </div>
  );
}

export default Rating;
