import { WagtailPage } from '@/types';
import RawHtml from '@components/RawHtml';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';
import React from 'react';

type Props = {
  layout: 'layout-1' | 'layout-2';
  text_link: string;
  link_intern: WagtailPage;
  link_extern: string;
  button_text_link: string;
  text_telephone: string;
  telephone: string;
  button_text_telephone: string;
};

function Reservation(props: Props) {
  const {
    text_link: textLink,
    link_intern: linkIntern,
    link_extern: linkExtern,
    text_telephone: textTelephone,
    button_text_link: buttonTextLink,
    telephone,
    button_text_telephone: buttonTextTelephone,
    layout,
  } = props;

  if (layout === 'layout-2') {
    return (
      <div className='streamfield reservation'>
        <div className='reservation__container'>
          <RawHtml html={textTelephone} />
          <Link href={`tel:${telephone}`} passHref>
            <a className='button reservation__link reservation__link--telephone'>
              {buttonTextTelephone}
            </a>
          </Link>
          <RawHtml html={textLink} />
          {linkIntern ? (
            <Link href={removeBaseUrl(linkIntern.full_url)} passHref>
              <a className='button button--primary reservation__link'>{buttonTextLink}</a>
            </Link>
          ) : null}
          {linkExtern ? (
            <Link href={linkExtern} passHref>
              <a className='button button--primary reservation__link'>{buttonTextLink}</a>
            </Link>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className='streamfield reservation'>
      <div className='reservation__container'>
        <RawHtml html={textLink} />
        {linkIntern ? (
          <Link href={removeBaseUrl(linkIntern.full_url)} passHref>
            <a className='button button--primary reservation__link'>{buttonTextLink}</a>
          </Link>
        ) : null}
        {linkExtern ? (
          <Link href={linkExtern} passHref>
            <a className='button button--primary reservation__link'>{buttonTextLink}</a>
          </Link>
        ) : null}
        <RawHtml html={textTelephone} />
        <Link href={`tel:${telephone}`} passHref>
          <a className='button reservation__link reservation__link--telephone'>
            {buttonTextTelephone}
          </a>
        </Link>
      </div>
    </div>
  );
}

export default Reservation;
