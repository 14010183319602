import clsx from 'clsx';
import React from 'react';

type Props = { html?: string; className?: string };

function RawHtml(props: Props) {
  const { html = '', className } = props;
  /* eslint-disable-next-line react/no-danger */
  return <div className={clsx('richtext', className)} dangerouslySetInnerHTML={{ __html: html }} />;
}

export default RawHtml;
