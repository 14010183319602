import { Streamfield, WagtailImage, WagtailMainMenu, WagtailSvg } from '@/types';
import React from 'react';
import Image from 'next/image';
import Navigation from '@components/Navigation';
import Link from 'next/link';
import { addBaseUrl } from '@utils/url';

type HeaderProps = {
  ariatext: string;
  image: WagtailImage | null;
  svg: WagtailSvg | null;
  contact?: Streamfield[];
};

type Props = { data: WagtailMainMenu; header?: HeaderProps | null };

function Header(props: Props) {
  const { header, data } = props;

  return (
    <header className='header container'>
      <Link href='/' passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className='header__logo'>
          {header?.image ? (
            <Image
              src={addBaseUrl(header.image.meta.download_url)}
              alt={header.image.title ?? ''}
              layout='responsive'
              width={235}
              height={120}
              priority
            />
          ) : null}
          {header?.svg ? (
            <Image
              src={header.svg.file}
              alt={header.svg.title ?? ''}
              layout='responsive'
              width={235}
              height={120}
              priority
            />
          ) : null}
        </a>
      </Link>
      <Navigation data={data} contact={header?.contact} />
    </header>
  );
}

export default Header;
