import React, { useEffect, useState } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { Accordion as AccordionType } from '@/types';
import clsx from 'clsx';
import Accordion from './Accordion';

type Props = {
  className?: string;
  accordions: AccordionType[];
  defaultOpen?: boolean;
};

function Accordions(props: Props) {
  const { accordions, className, defaultOpen } = props;

  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    if (defaultOpen && window?.innerWidth > 640 && accordions?.[0]) {
      setValue([accordions[0].title]);
    }
  }, [defaultOpen, accordions]);

  return (
    <AccordionPrimitive.Root
      className={clsx('flow', className)}
      type='multiple'
      value={value}
      onValueChange={setValue}
    >
      {accordions.map(accordion => (
        <Accordion key={accordion.title} title={accordion.title} text={accordion.text} />
      ))}
    </AccordionPrimitive.Root>
  );
}

export default Accordions;
