import RawHtml from '@components/RawHtml';
import dynamic from 'next/dynamic';
import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';

const Popover = dynamic(() => import('@components/Popover'));

type KursTypField = {
  title: string;
  field?: { key: string; value: string };
  text?: string;
  helptext: string | null;
};

type Field = {
  id: string;
  type: 'kurstypfield' | 'customkurstypfield';
  value: KursTypField;
};

type Props = {
  title: string;
  content: Field[];
};

function CourseInformation(props: Props) {
  const { title, content } = props;

  return (
    <div className='streamfield course-information'>
      <h2>{title}</h2>
      <ul className='course-information__list'>
        {content?.map(item => (
          <li className='course-information__list-item' key={item.id}>
            <h3>{item.value.title}</h3>
            {item.value.text ? <RawHtml html={item.value.text} /> : null}
            {item.value.field ? <p>{item.value.field.value}</p> : null}
            {item.value.helptext ? (
              <Popover content={item.value.helptext} buttonText={<BsQuestionCircle size={20} />} />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CourseInformation;
