import { WagtailStreamfieldImage } from '@/types';
import clsx from 'clsx';
import React from 'react';
import NextImage from 'next/image';
import { addBaseUrl } from '@/utils/url';

type Props = {
  image: WagtailStreamfieldImage;
  full_width: boolean;
};

function Image(props: Props) {
  const { full_width: fullWidth, image } = props;

  return (
    <div
      className={clsx('streamfield', 'image', {
        'image--full-width': fullWidth,
      })}
    >
      <NextImage
        className='image__element'
        src={addBaseUrl(image.file.src)}
        alt={image.file.alt}
        layout='responsive'
        objectFit='cover'
        width={image.file.width}
        height={image.file.height}
      />
    </div>
  );
}

export default Image;
