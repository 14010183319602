import Link from 'next/link';
import type { Streamfield, WagtailMainMenu } from '@/types';
import React, { useEffect, useRef, useState } from 'react';
import { removeBaseUrl } from '@utils/url';
import { useRouter } from 'next/router';

import { TbCaretDown } from 'react-icons/tb';

import * as NavMenu from '@radix-ui/react-navigation-menu';
import Hamburger from '@components/Hamburger';
import Streamfields from '@streamfields/Streamfields';
import dynamic from 'next/dynamic';

const Popover = dynamic(() => import('@components/Popover'));

type Props = {
  data: WagtailMainMenu;
  contact?: Streamfield[];
};

function Navigation(props: Props) {
  const { data, contact } = props;
  const navRef = useRef<HTMLElement>(null);
  const router = useRouter();

  const [forceMount, setForceMount] = useState<true | undefined>(true);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    setForceMount(undefined);
  }, []);

  useEffect(() => {
    function match(e: MediaQueryListEventMap['change']) {
      setIsDesktop(e.matches);
    }

    const mq = window.matchMedia('(min-width: 600px)');
    setIsDesktop(mq.matches);

    mq.addEventListener('change', match);

    return () => mq.removeEventListener('change', match);
  }, []);

  useEffect(() => {
    function handleRouteChange() {
      navRef.current?.classList.remove('nav--open');
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  if (!data?.items?.length) return null;

  return (
    <>
      <NavMenu.Root
        className='nav'
        aria-label='Hauptnavigation'
        id='main-nav'
        orientation='horizontal'
        ref={navRef}
      >
        <NavMenu.List className='nav__list' role='list'>
          {data?.items?.map(item => {
            if (item.children.length) {
              return (
                <NavMenu.Item className='nav__list-item' key={item?.text ?? item?.page?.title}>
                  <NavMenu.Trigger className='nav__trigger'>
                    {item.text ?? item.page.title}
                    <TbCaretDown aria-hidden className='nav__caret' />
                  </NavMenu.Trigger>
                  <NavMenu.Content
                    className='nav__content'
                    forceMount={forceMount}
                    hidden={forceMount}
                  >
                    <NavMenu.NavigationMenuSub>
                      <NavMenu.NavigationMenuList className='nav__content-list'>
                        {item.children.map(child => (
                          <NavMenu.Item className='nav__sub-list-item' key={child.text}>
                            <Link href={removeBaseUrl(child.href)} passHref>
                              <NavMenu.Link className='nav__link'>{child.text}</NavMenu.Link>
                            </Link>
                          </NavMenu.Item>
                        ))}
                      </NavMenu.NavigationMenuList>
                    </NavMenu.NavigationMenuSub>
                  </NavMenu.Content>
                </NavMenu.Item>
              );
            }

            return (
              <NavMenu.Item className='nav__list-item' key={item.href}>
                <Link href={removeBaseUrl(item.href)} passHref>
                  <NavMenu.Link className='nav__link'>
                    {item?.text ?? item?.page?.title}
                  </NavMenu.Link>
                </Link>
              </NavMenu.Item>
            );
          })}
          {contact ? (
            <Popover
              className='nav__contact'
              containerClassName='nav__contact--mobile-only'
              content={<Streamfields fields={contact} />}
              buttonText='Kontakt'
            />
          ) : null}
          <NavMenu.Indicator className='nav__indicator'>
            <div className='nav__arrow' />
          </NavMenu.Indicator>
        </NavMenu.List>
        {isDesktop ? (
          <div className='nav__viewport-position'>
            <NavMenu.Viewport className='nav__viewport' />
          </div>
        ) : null}
      </NavMenu.Root>
      {contact ? (
        <Popover
          className='nav__contact'
          content={<Streamfields fields={contact} />}
          buttonText='Kontakt'
        />
      ) : null}
      <Hamburger
        onClick={() => {
          navRef?.current?.classList.toggle('nav--open');
        }}
        aria-label='Navigation umschalten'
        aria-controls='main-nav'
      />
    </>
  );
}

export default Navigation;
