import { Accordion } from '@/types';
import React from 'react';
import AccordionsComponent from '@components/Accordions';

type Props = {
  accordions: Accordion[];
  blocktitle?: string;
};

function Accordions(props: Props) {
  const { accordions, blocktitle } = props;

  return (
    <div className='streamfield accordions'>
      {blocktitle ? <h2 className='accordions__header'>{blocktitle}</h2> : ''}
      <AccordionsComponent accordions={accordions} defaultOpen />
    </div>
  );
}

export default Accordions;
