import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';
import React from 'react';
import PhoneIcon from '@icons/phone.svg';

const year = new Date().getFullYear();

type FooterMenu = {
  id: number;
  title: string;
  link_intern_full_url: string | null;
  link_extern: string | null;
};

type Props = {
  data: {
    social_media: unknown[];
    footer_menu: FooterMenu[];
  };
};

function Footer(props: Props) {
  const { data } = props;

  return (
    <footer className='footer'>
      <ul className='footer__links'>
        <li>
          <a href='tel:+498008000016' className='footer__link footer__link--tel'>
            <PhoneIcon /> Jetzt anrufen und informieren
          </a>
        </li>
        {data?.footer_menu?.map(entry => (
          <li key={entry.id}>
            {entry.link_intern_full_url ? (
              <Link href={removeBaseUrl(entry.link_intern_full_url)} passHref>
                <a className='footer__link'>{entry.title}</a>
              </Link>
            ) : null}
            {entry.link_extern ? (
              <Link href={entry.link_extern} passHref>
                <a className='footer__link'>{entry.title}</a>
              </Link>
            ) : null}
          </li>
        ))}
        {process.env.NEXT_PUBLIC_VINEGAR_ENABLED === 'true' ? (
          <li>
            <button
              className='footer__link'
              onClick={() => {
                // @ts-expect-error vinegar
                window?.vinegar?.show();
              }}
            >
              Datenschutzeinstellungen
            </button>
          </li>
        ) : null}
      </ul>
      <p className='footer__copyright'>&copy; {year} Stiftung Bildung &amp; Handwerk</p>
    </footer>
  );
}

export default Footer;
